import { BarcodesHtml, ItemsBarcodeTable } from "./ItemsBarcodeTable";

export const ItemsBarcodePDF = async (checkedItems) => {
  if (checkedItems.length > 0) {
    let html = "";
    const barcodePerPage = 65;
    const barcodePerRow = 5;
    let itemHtml = "";
    let barcodeCount = 0;

    for (let i = 0; i < checkedItems.length; i++) {
      const barcodeValue = checkedItems[i].barcodeNo;
      const count = parseInt(checkedItems[i].barcodeCount);
      const barcodeBase64 = checkedItems[i].barcodrUrl;

      for (let j = 0; j < count; j++) {
        if (barcodeCount % barcodePerPage === 0 && barcodeCount > 0) {
          html += ItemsBarcodeTable.replace("{BARCODES}", itemHtml);
          itemHtml = "";
        }

        if (barcodeCount % barcodePerRow === 0) {
          itemHtml += "<tr>";
        }

        itemHtml += BarcodesHtml
          .replace(/{BARCODE_VALUE}/g, barcodeValue)
          .replace(/{BARCODE_BASE64}/g, barcodeBase64);

        if ((barcodeCount + 1) % barcodePerRow === 0) {
          itemHtml += "</tr>";
        }
        barcodeCount++;
      }
    }

    if (itemHtml) {
      html += ItemsBarcodeTable.replace("{BARCODES}", itemHtml);
    }

    return html;
  }

  return '';
};
