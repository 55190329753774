import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"
import {
    useTable,
    useGlobalFilter,
    useAsyncDebounce,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
} from "react-table"
import { Table, Row, Col, Button, Input, CardBody, FormGroup, Label } from "reactstrap"
// import { Filter, DefaultColumnFilter } from "./filters"
import { DefaultColumnFilter } from "components/Common/filters"
import JobListGlobalFilter from "../../../components/Common/GlobalSearchFilter"
import { useEffect } from "react"

// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    isJobListGlobalFilter,
    filterData,
    value,
    setValue,
}) {
    const count = preGlobalFilteredRows.length
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
        filterData(value)
    }, 200)

    return (
        <React.Fragment>
            <Col md={12}>
                <div className="search-box w-full d-inline-block">
                    <div className="position-relative">
                        <label htmlFor="search-bar-0" className="search-label">
                            <span id="search-bar-0-label" className="sr-only">
                                Search this table
                            </span>
                            <input
                                onChange={e => {
                                    setValue(e.target.value)
                                    onChange(e.target.value)
                                }}
                                id="search-bar-0"
                                type="text"
                                className="form-control"
                                placeholder={"Search…"}
                                value={value || ""}
                            />
                        </label>
                        <i className="bx bx-search-alt search-icon"></i>
                    </div>
                </div>
            </Col>
            {isJobListGlobalFilter && <JobListGlobalFilter />}
        </React.Fragment>
    )
}

const ItemsBarcodeCountTable = ({
    columns,
    data,
    className,
    isLoading,
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        gotoPage,
        setPageSize,
    } = useTable(
        {
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter },
            initialState: {
                pageSize: 10000000,
                sortBy: [
                    {
                        desc: true,
                    },
                ],
            },
        },
        useGlobalFilter,
        useExpanded,
        usePagination
    )
    const handlesort = column => {
    }

    const generateSortingIndicator = column => {
        return column.isSorted ? (column.isSortedDesc ? "🔽" : "🔼") : ""
    }

    const onChangeInSelect = event => {
        setPageSize(Number(event.target.value))
    }


    const onChangeInInput = event => {
        const page = event.target.value ? Number(event.target.value) - 1 : 0
        gotoPage(page)
    }

    return (
        <Fragment>
            <div className="table-responsive react-table">
                <Table bordered hover {...getTableProps()} className={className}>
                    <thead className="table-light table-nowrap">
                        {headerGroups.map(headerGroup => (
                            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th
                                        key={column.id}
                                        onClick={() => {
                                            handlesort(column)
                                        }}
                                        // className={` ${column.Header == 'BarcodeUrl' ? 'column-display' : ""} `}
                                        {...column.getHeaderProps({
                                            style: {
                                                minWidth: column.minWidth,
                                                maxWidth: column.maxWidth,
                                                width: column.width,
                                                alignItems: column.alignItems,
                                                textAlign: column.textAlign,
                                            },
                                        })}
                                    >
                                        <div className={`mb-0`}>
                                            {column.render("Header")}
                                            {generateSortingIndicator(column)}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    {page.length ? (
                        <tbody {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row)
                                return (
                                    <Fragment key={row.getRowProps().key}>
                                        <tr>
                                            {row.cells.map(cell => {
                                                return (
                                                    <td
                                                        key={cell.id}
                                                        className={`text-nowrap`}
                                                        // className={`text-nowrap ${cell.column.Header == 'BarcodeUrl'? 'column-display' : ""} `}
                                                        {...cell.getCellProps({
                                                            style: {
                                                                minWidth: cell.minWidth,
                                                                maxWidth: cell.maxWidth,
                                                                width: cell.width,
                                                            },
                                                        })}
                                                    >
                                                        {cell.render("Cell")}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    </Fragment>
                                )
                            })}
                        </tbody>
                    ) : (
                        <tbody
                            style={{
                                textAlign: "center",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "40px",
                            }}
                        >
                            <tr>
                                <td colSpan={headerGroups[0].headers.length}>
                                    {isLoading ? ("Loading...") : ("No Record Found")}
                                </td>
                            </tr>
                        </tbody>
                    )}
                </Table>
            </div>
        </Fragment>
    )
}

ItemsBarcodeCountTable.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default ItemsBarcodeCountTable